import React from "react";
import "../assets/style/globalStyle.scss";
import { useMediaQuery } from "react-responsive";
import arrowRight from "../assets/img/arrow_right.png";

const ArticleCard = ({ title, content, date, img, link, author }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const styles = {
    container: {
      width: isMobile ? "98%" : "90%",
      borderRadius: "5px",
      marginBottom: 25,
      marginLeft: !isMobile ? "25px" : "0px",
      marginRight: !isMobile ? "25px" : "0px",
      height: 573,
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      border: "1px solid #D2D2D2",
    },
    title: {
      fontSize: "24px",
      fontWeight: 800,
      color: "black",
      marginTop: 13,
    },
    content: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#000000",
      marginTop: 18,
      textAlign: "justify",
    },
    image: {
      borderRadius: 50,
      width: 60,
    },
    date: {
      fontSize: "14px",
      fontWeight: 400,
      color: "black",
      opacity: 0.5,
      marginTop: 32,
    },
    link: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: "#F56D44",
      fontSize: "14px",
      fontWeight: 700,
      marginBottom: 25,
    },
  };
  return (
    <div style={styles.container} aria-hidden="false">
      <div>
        <img
          src={img}
          alt="article_img"
          style={{ height: 222, width: "100%", objectFit: "cover" }}
        ></img>
      </div>
      <div
        style={{
          paddingLeft: 25,
          paddingRight: 25,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <p style={styles.date}>
            {date} par {author}
          </p>
          <p style={styles.title}>{title.substring(0, 55) + "..."}</p>
          <p style={styles.content}>{content.substring(0, 180) + "..."}</p>
        </div>
        <div>
          <a href={link} style={styles.link}>
            LIRE PLUS
            <img
              src={arrowRight}
              alt="profile"
              style={{ marginLeft: 14 }}
            ></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
