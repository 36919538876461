import React from "react";
import "../assets/style/globalStyle.scss";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
    faUser
} from "@fortawesome/free-solid-svg-icons";

const OpinionCard = ({
    title,
    content,
    name,
    img
}) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const styles = {
        container: {
            width: isMobile ? 350 : "90%",
            boxShadow: "0px 1px 10px 6px rgba(0, 0, 0, 0.05)",
            borderRadius: "10px",
            marginTop: 60,
            marginBottom: 110,
            padding: 30,
            height: 313,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "white",
            alignSelf: "center",
            marginLeft: "auto",
            marginRight: "auto"
        },
        title: {
            fontSize: "18px",
            fontWeight: 700,
            color: "#F56D44"
        },
        content: {
            fontSize: "14px",
            fontWeight: 400,
            color: "black",
            marginTop: 10,
            textAlign: "justify"
        },
        image: {
            borderRadius: 50,
            width: 60
        },
        name: {
            fontSize: "14px",
            fontWeight: 700,
            color: "black",
            marginLeft: 12
        },
        userContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        }
    }

    return (
        <div style={styles.container} tabIndex={-1}>
            <div>
                <p style={styles.title}>{title}</p>
                <p style={styles.content}>{content}</p>
            </div>
            <div style={styles.userContainer}>
                <Icon icon={faUser} size="2x" />
                <p style={styles.name}>{name}</p>
            </div>
        </div>
    );
};

export default OpinionCard;
