import React from "react";
import "../assets/style/globalStyle.scss";
import styled from "styled-components";
import linkedinimg from "../assets/social/linkedin.png";

const styles = {
  container: {
    width: 300,
    boxShadow: "0px 1px 21px 6px rgba(0, 0, 0, 0.08)",
    borderRadius: "9px",
    marginTop: 55,
    marginBottom: 230,
    marginLeft: "auto",
    marginRight: "auto",
  },
  image: {
    width: 300,
    height: 300,
    borderRadius: "9px 9px 0px 0px",
    objectFit: "cover",
    objectPosition: "top",
  },
  cardInfos: {
    padding: 16,
    width: 300,
    borderRadius: "10px",
    position: "absolute",
    marginTop: -20,
    backgroundColor: "white",
    boxShadow: "0px 1px 21px 6px rgba(0, 0, 0, 0.08)",
  },
  cardName: {
    fontSize: "18px",
    fontWeight: 800,
    color: "black",
  },
  cardPoste: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    opacity: 0.8,
    marginBottom: 15,
  },
  socialSquare: {
    backgroundColor: "#F7F7F7",
    width: 42,
    height: 42,
    marginRight: 16,
    padding: 11,
  },
};

const HoverSocial = styled.p`
  width: 18px;
  height: 18px;
  :hover {
    height: 21px;
    width: 21px;
  }
`;

const TeamCard = ({ name, poste, linkedin, twitter, facebook, img }) => {
  return (
    <div style={styles.container} aria-hidden="false" tabIndex={-1}>
      <img src={img} style={styles.image} alt="profile"></img>
      <div style={styles.cardInfos}>
        <p style={styles.cardName}>{name}</p>
        <p style={styles.cardPoste}>{poste}</p>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={styles.socialSquare}>
            <a href={linkedin}>
              <HoverSocial>
                <img src={linkedinimg} alt="logo"></img>
              </HoverSocial>
            </a>
          </div>
          {/* <div style={styles.socialSquare}>
                        <a href="https://www.facebook.com/ballnconnect/" >
                            <HoverSocial>
                                <img
                                    src={require("../assets/social/facebook.png")}
                                    alt="logo"
                                ></img>
                            </HoverSocial>
                        </a>
                    </div>
                    <div style={styles.socialSquare}>
                        <a href="https://twitter.com/ballnconnect">
                            <HoverSocial>
                                <img
                                    src={require("../assets/social/twitter.png")}
                                    alt="logo"
                                ></img>
                            </HoverSocial>
                        </a>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
